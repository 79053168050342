/*
 * SEACOM 3.x Global Styles
 *
 *  Index (Ctrl+F to locate): 
 *   #Globals : Global styles
 *   #Colors : Color associated styles
 *   #Tags : Tag associated styles
 *   #Classes : Class associated styles
 *
 */

/* #Globals */

@font-face {
	font-family: 'Calibri';
	src: url('../assets/fonts/Calibri.woff');
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(200, 200, 200);
	border-radius: 4px !important;
}

::-webkit-scrollbar-thumb {
	background: rgba(131, 125, 125, 0.294);
	border-radius: 4px !important;
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(128, 128, 128, 0.52);
}

:root {
	font-family: Calibri, sans-serif;

	ion-page {
		display: grid;
		grid-template-columns: 1fr;

	}
}

/* #Colors */

:root {
  /* Transparent */
  --color-transparent: transparent;

  /* White */
  --color-white-light: #ffffff;
  --color-white-dark: #000000;
  --color-white-trans-light: rgba(255, 255, 255, .7);
  --color-white-trans-dark: rgba(0, 0, 0, .3);

  /* Black */
  --color-black-light: #000000;
  --color-black-dark: #ffffff;

  /* Grays */
  --color-gray-1-light: #111111;
  --color-gray-2-light: #222222;
  --color-gray-3-light: #333333;
  --color-gray-4-light: #444444;
  --color-gray-5-light: #555555;
  --color-gray-6-light: #666666;
  --color-gray-7-light: #777777;
  --color-gray-8-light: #888888;
  --color-gray-9-light: #999999;
  --color-gray-10-light: #AAAAAA;
  --color-gray-11-light: #BBBBBB;
  --color-gray-12-light: #CCCCCC;
  --color-gray-13-light: #DDDDDD;
  --color-gray-14-light: #EEEEEE;
  --color-gray-1-dark: #EEEEEE;
  --color-gray-2-dark: #DDDDDD;
  --color-gray-3-dark: #CCCCCC;
  --color-gray-4-dark: #BBBBBB;
  --color-gray-5-dark: #AAAAAA;
  --color-gray-6-dark: #999999;
  --color-gray-7-dark: #888888;
  --color-gray-8-dark: #777777;
  --color-gray-9-dark: #666666
  --color-gray-10-dark: #555555;
  --color-gray-11-dark: #444444;
  --color-gray-12-dark: #333333;
  --color-gray-13-dark: #222222;
  --color-gray-14-dark: #111111;

  /* Green */
  --color-green-light: #2fdf75;
  --color-green-dark: #00cc44;

  /* Red */
  --color-red-light: #ff4961;
  --color-red-dark: #cc0044;

  /* Blues */
  --color-blue-0-light: #bedbe3;
  --color-blue-0-dark: #99a9b5;
  --color-blue-1-light: #045c79;
  --color-blue-1-dark: #033b53;
  --color-blue-2-light: #d5e0ec;
  --color-blue-2-dark: #b3c1d1;
  --color-blue-3-light: #3497da;
  --color-blue-3-dark: #006699;
  --color-blue-4-light: #c2d7de;
  --color-blue-4-dark: #99b3c2;
  --color-blue-5-light: #e5f7f9;
  --color-blue-5-dark: #99c2c7;
  --color-blue-6-light: #e4f6f8;
  --color-blue-6-dark: #b3d1d3;
  --color-blue-7-light: #aad4de;
  --color-blue-7-dark: #045c79;

  /* Turquoise */
  --color-turquoise-light: #00c2c7;
  --color-turquoise-dark: #008889;
}


/* #Tags */

ion-card {
	border-radius: .625rem !important;
}

ion-select::part(container) {
  width: 100% !important;
}

/* Classes */
center {
	display: block;
	text-align: center !important;
	width: 100%;
	position: absolute;
	bottom: 15px;
}

ion-toast.success-toast {
	--width: fit-content;
	font-size: 1rem;
}

ion-toast.failure-toast {
	--width: fit-content;
	font-size: 1rem;
}

.login-error-toast {
	--width: fit-content !important;
	--box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2) !important;
}

.login-error-toast::part(message) {
	font-family: Calibri, sans-serif !important;
	font-size: 1.25rem !important;
	line-height: 1.15 !important;
}

/* light theme styles */
@media (prefers-color-scheme: light) {
	ion-toast.success-toast {
		--background: $color-green-light;
		--color: $color-black-light;
	}

	.login-error-toast {
		--background: $color-red-light !important;
		--color: $color-black-light !important;
	}
}

/* dark theme styles */
@media (prefers-color-scheme: dark) {
	ion-toast.success-toast {
		--background: $color-green-dark;
		--color: $color-black-dark;
	}

	.login-error-toast {
		--background: $color-red-dark !important;
		--color: $color-black-dark !important;
	}
}

.mat-mdc-table {
	border: none !important;

	border-collapse: separate !important;

	thead,
	tbody,
	thead tr,
	tbody tr,
	thead tr th,
	tbody tr td {
		border: none;
		background-color: none;
	}

	thead {
		overflow: unset !important;
	}
}

input[type=search]::-webkit-search-cancel-button {
	-webkit-appearance: searchfield-cancel-button !important;
}
